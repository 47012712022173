<script>
import Status from "../../../../../../helpers/general/status";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            stock_status: Status.getList('stock_status'),
            backorder_status: Status.getList('backorder_status'),
        }
    },
    components: {        
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="sku">SKU</label>
            <input
                id="sku"
                v-model="value.sku"
                type="text"
                class="form-control"
                placeholder="Ingresa el SKU del producto"
                :class="{ 'is-invalid': v.product.sku.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <label for="manage_stock">Gestión de inventario</label>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.manage_stock"
                    class="custom-control-input"
                    id="manage_stock"
                    :class="{ 'is-invalid': v.product.manage_stock.$error && submitted }"
                />
                <label class="custom-control-label" for="manage_stock">Hacer seguimiento de la cantidad de inventario de este producto</label>
            </div>
        </div>
        <div class="form-group col-12" v-if="value.manage_stock">
            <label for="stock_quantity">Cantidad</label>
            <input
                id="stock_quantity"
                v-model="value.stock_quantity"
                type="text"
                class="form-control"
                placeholder="Ingresa la cantidad de inventario del producto"
                :class="{ 'is-invalid': v.product.stock_quantity.$error && submitted }"
            />
        </div>
        <div class="form-group col-12" v-if="value.manage_stock">
            <label for="backorders">¿Permitir reservas?</label>
            <select v-model="value.backorders" class="form-control"
                :class="{ 'is-invalid': v.product.backorders.$error && submitted }">
                <option v-for="option in backorder_status" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>                                        
        <div class="form-group col-12" v-if="!value.manage_stock && value.type === 'simple'">
            <label for="stock_status">Estado de inventario</label>
            <select v-model="value.stock_status" class="form-control"
                :class="{ 'is-invalid': v.product.stock_status.$error && submitted }">
                <option v-for="option in stock_status" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label for="sold_individually">Vendido individualmente</label>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.sold_individually"
                    class="custom-control-input"
                    id="sold_individually"
                    :class="{ 'is-invalid': v.product.sold_individually.$error && submitted }"
                />
                <label class="custom-control-label" for="sold_individually">Limitar compras a 1 artículo por pedido</label>
            </div>
        </div>                                     
    </div> 
</template>