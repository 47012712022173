<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import productForm from "./forms/product-form.vue";
import productShow from "./show/show.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";


/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    productForm,
    productShow,
    CustomTable,
    SelectAutocompleteField,
  },
  data() {
    return {
      formModalId:'product-form-modal',
      showModalId:'product-show-modal',
      pageHeader:{
        title: "Productos",
        breadcrumbs: [
          {
            text: "Productos",
            href: "/products",
            active: true
          }
        ],
      },
      product_status: Status.getList('products'),
      table:{
        url:'/products',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "sku", label: "SKU", sortable: true },
          { key: "slug", label: "Slug", sortable: true },
          { key: "integration.name", label: "Origen", sortable: false },
          { key: "status", label: "Estado", sortable: true },
        ],
        formatted_fields:[
          'status'
        ]
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showEditModal(item, clone = false) {
      this.formSelectedItemId = item?.id || null;
      this.clone = clone;
      this.$bvModal.show(this.formModalId);
    },
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    onSuccess(){
      this.$refs.customerTable.refresh();
    },
    formatter(field, item){
      if(field === 'status'){
        const status = Status.getStatus({itemStatus: item?.status, type: 'products'});
        return status;
      }
      return '';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <productForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess" :clone="clone"></productForm>
            <productShow :modal-id="showModalId" :id="showSelectedItemId"></productShow>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :formatter="formatter"
              :params="table.params"
              @edit="showEditModal"
              ref="customerTable"
              :show-view="false"
              :showSoftDelete="false"
              @show="showViewModal"
              :show-modal-id="showModalId"
            >
              <template v-slot:header-actions>
                <AddButton @click="showEditModal"></AddButton>
              </template>
              <template v-slot:filters="{ filters }">
                <div class="row">
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="sku">SKU</label>
                        <input id="sku" v-model="filters.sku" type="text" class="form-control" placeholder="Ingresa un SKU para filtrar"/>
                    </div>
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="sku">Estado</label>
                        <select v-model="filters.status" class="form-control" placeholder="Todos">
                            <option value="">Todos</option>
                            <option v-for="option in product_status" v-bind:value="option.id" :key="option.id">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="name">Origen</label>
                        <SelectAutocompleteField :url="`/integrations`" :only-ids="true" :multiple="true" :placeholder="'Todos'" v-model="filters.integration_id"></SelectAutocompleteField>
                    </div>
                </div> 
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>