<script>
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {        
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="purchase_note">Nota de compra</label>
            <p class="text-muted font-13">Introduce una nota opcional para enviar al cliente después de la compra.</p>
            <textarea
                id="purchase_note"
                v-model="value.purchase_note"
                class="form-control"
                :maxlength="225"
                rows="3"
                placeholder="Ingresa una nota de compra para este producto"
                :class="{ 'is-invalid': v.product.purchase_note.$error && submitted }"
            ></textarea>
        </div>
        <div class="form-group col-12">
            <label for="menu_order">Orden en el menú</label>
            <input
                id="menu_order"
                v-model="value.menu_order"
                type="text"
                class="form-control"
                placeholder="Posición de orden personalizado."
                :class="{ 'is-invalid': v.product.menu_order.$error && submitted }"
            />
        </div>
        <div class="form-group col-12">
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.reviews_allowed"
                    class="custom-control-input"
                    id="reviews_allowed"
                    :class="{ 'is-invalid': v.product.reviews_allowed.$error && submitted }"
                />
                <label class="custom-control-label" for="reviews_allowed">Activa las valoraciones</label>
            </div>
        </div>                                 
    </div>
</template>