<script>
import draggable from "vuedraggable";
export default {
    props: {
        value: Array,
        product_type: String,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {   
        draggable,
    },
    methods: {
        addAttribute(){
            this.value.push({
                name: '',
                options: '',
                position: '',
                visible: true,
                variation: true,
                default_option: '',
            });
        },
        deleteAttribute(key){
            this.value.splice(key, 1);
        },
    },
};
</script>

<template>
    <div>
        <div role="tablist">
            <draggable group="product-attributes" :list="value">
                <b-card no-body class="mb-1 shadow-none" v-for="(attribute, i) in value" :key="i" :set="vattr = v.product.product_attributes.$each[i]">
                    <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0">
                        <a
                        v-b-toggle="'accordion-' + i"
                        class="text-dark"
                        href="javascript: void(0);"
                        >{{attribute.name || 'Atributo nuevo'}}</a>
                        <div class="float-right">
                            <a
                                href="javascript:void(0);"
                                class="text-secondary"
                                v-b-tooltip.hover
                                title="Ordenar"
                            >
                                <i class="mdi mdi-sort font-size-18"></i>
                            </a>
                            
                            <a
                                href="javascript:void(0);"
                                class="text-danger"
                                v-b-tooltip.hover
                                title="Eliminar"
                                @click="deleteAttribute(i)"
                            >
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>                                                        
                        </div>  
                    </h6>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + i" visible accordion="attibute-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <label for="weight">Nombre</label>
                                        <input
                                            id="name"
                                            v-model="attribute.name"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa el nombre del atributo"
                                            :class="{ 'is-invalid': vattr.name.$error && submitted }"
                                        />
                                    </div>
                                    <div class="form-group col-12">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                v-model="attribute.visible"
                                                class="custom-control-input"
                                                :id="'visible-' + i"
                                                :class="{ 'is-invalid': vattr.visible.$error && submitted }"
                                            />
                                            <label class="custom-control-label" :for="'visible-' + i">Visible en la página de productos</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-12" v-if="product_type === 'variable'">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                v-model="attribute.variation"
                                                class="custom-control-input"
                                                :id="'variation-' + i"
                                                :class="{ 'is-invalid': vattr.variation.$error && submitted }"
                                            />
                                            <label class="custom-control-label" :for="'variation-' + i">Usado para variaciones</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-7">
                                    <div class="form-group">
                                        <label :for="'options-' + i">Opciones</label>
                                        <p class="text-muted font-13">Introduce un texto descriptivo. Utiliza «|» para separar los distintos valores.</p>
                                        <textarea
                                            :id="'options-' + i"
                                            v-model="attribute.options"
                                            class="form-control"
                                            :maxlength="225"
                                            rows="3"
                                            placeholder="Ingresa la lista de opciones separadas por |"
                                            :class="{ 'is-invalid': vattr.options.$error && submitted }"
                                        ></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label for="weight">Opción por defecto</label>
                                        <input
                                            id="default_option"
                                            v-model="attribute.default_option"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa la opción por defecto"
                                            :class="{ 'is-invalid': vattr.default_option.$error && submitted }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-card-body>
                    </b-collapse>
                </b-card>
            </draggable>
        </div>
        <b-alert
            show
            variant="secondary"
            class="text-center"
            v-if="!value.length"
        >
            <a
                href="javascript:void(0);"
                class="text-success"
                @click="addAttribute()"
            >
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar attributo
            </a>
        </b-alert>
        <div class="text-right" v-if="value.length">
            <a
                href="javascript:void(0);"
                class="text-success"
                @click="addAttribute()"
            >
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar attributo
            </a>
        </div>
    </div>
</template>