<script>
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {        
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="weight">Peso (kg)</label>
            <input
                id="weight"
                v-model="value.weight"
                type="text"
                class="form-control"
                placeholder="Ingresa el peso del producto"
                :class="{ 'is-invalid': v.product.weight.$error && submitted }"
            />
        </div>                                   
        <div class="form-group col-12 col-lg-4">
            <label for="length">Longitud (cm)</label>
            <input
                id="length"
                v-model="value.length"
                type="text"
                class="form-control"
                placeholder="Ingresa la longitud del producto"
                :class="{ 'is-invalid': v.product.length.$error && submitted }"
            />
        </div>                                    
        <div class="form-group col-12 col-lg-4">
            <label for="width">Ancho (cm)</label>
            <input
                id="width"
                v-model="value.width"
                type="text"
                class="form-control"
                placeholder="Ingresa el ancho del producto"
                :class="{ 'is-invalid': v.product.width.$error && submitted }"
            />
        </div>                                    
        <div class="form-group col-12 col-lg-4">
            <label for="height">Alto (cm)</label>
            <input
                id="height"
                v-model="value.height"
                type="text"
                class="form-control"
                placeholder="Ingresa el alto del producto"
                :class="{ 'is-invalid': v.product.height.$error && submitted }"
            />
        </div>   
        <div class="form-group col-12">
            <label for="shipping_class">Clase de envío</label>
            <input
                id="shipping_class"
                v-model="value.shipping_class"
                type="text"
                class="form-control"
                placeholder="Ingresa el slug de la clase de envío"
                :class="{ 'is-invalid': v.product.shipping_class.$error && submitted }"
            />
        </div>                            
    </div> 
</template>