<script>
export default {
  name: 'QlkTree',
  props: {
    items: Array,
    selected: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCheck(item, isChecked) {
      const newSelected = isChecked
        ? [...this.selected, item.id]
        : this.selected.filter(id => id !== item.id);

      this.$emit('update:selected', newSelected);
    }
  }
}
</script>

<template>
  <div class="qlk-tree">
    <ul v-if="items">
      <li v-for="item in items" :key="item.id">
        <input type="checkbox"
              :value="item.id"
              :id="item.id"
              :checked="selected.includes(item.id)"
              @change="handleCheck(item, $event.target.checked)">
        <label :for="item.id">{{ item.name }}</label>
        <qlk-tree v-if="item.children && item.children.length"
                  :items="item.children"
                  :selected="selected"
                  @update:selected="$emit('update:selected', $event)"/>
      </li>
    </ul>
  </div>
</template>